<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item>
						<el-input v-model.trim="filters.keyworld" placeholder="输入内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="add()" icon="el-icon-plus">添加标识</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="id" align="center">
			</el-table-column>
			<el-table-column prop="identifier" label="标识" align="center">
			</el-table-column>
			<el-table-column prop="name" label="简称" align="center">
			</el-table-column>
			<el-table-column prop="create_at" label="创建时间" align="center">
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="edit(scope.row)">编辑</el-button>
					<el-button type="danger" plain size="small" @click="del(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-col>

		<el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px">
			<el-form :model="form">
				<el-form-item label="标识" label-width="120px">
					<el-input v-model="form.identifier" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="简称" label-width="120px">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="push()">确 定</el-button>
			</div>
		</el-dialog>

	</section>
</template>

<script>
import { identifier, addidentifier, upidentifier, deleteidentifier } from '../../api/api';
export default {
	data() {
		return {
			title: '添加标识',
			type: 1,
			form: {
				id: '',
				name: '',
				identifier: '',
			},
			filters: {
				keyworld: ''
			},
			dialogFormVisible: false,
			listData: [],
			//分页
			total: 0,
			page: 1,
			pagesize: 10,//每页条数
			pagesizes: [10, 20, 50, 100],//可选条数
			listLoading: false,
		}
	},
	methods: {
		//分页
		handleSizeChange(val) {
			this.pagesize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		//获取用户列表
		getList(val) {
			if (val == '0') this.page = 1
			let para = {
				page: this.page,
				keyworld: this.filters.keyworld,
				pagesize: this.pagesize	//分页条数
			};
			this.listLoading = true;
			identifier(para).then((res) => {
				this.listLoading = false;
				if (res.code == 1) {
					this.total = res.data.count;
					this.listData = res.data.data;
					this.listLoading = false;
				} else {
					this.$notify({
						title: '错误',
						message: res.msg,
						type: 'error'
					});
				}
			});
		},
		add: function () {
			this.title = '添加标识';
			this.type = 0;
			this.dialogFormVisible = true;
			this.form.name = '';
			this.form.identifier = '';
			this.form.id = 0;
			console.log(this.form);
		},
		edit: function (data) {
			this.title = '编辑标识';
			this.type = 1;
			this.dialogFormVisible = true;
			this.form.id = data.id;
			this.form.name = data.name;
			this.form.identifier = data.identifier;
		},
		del: function (id) {
			this.$confirm('此操作将永久删除系统标识, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let para = {
					id: id
				};
				deleteidentifier(para).then((res) => {
					if (res.code == 1) {
						this.getList();
						this.$message({
							showClose: true,
							message: '删除成功！',
							type: 'success'
						});
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		push: function () {
			if (this.type == 0) {//新增
				addidentifier(this.form).then((res) => {
					if (res.code == 1) {
						this.form.name = '';
						this.form.identifier = '';
						this.form.id = 0;
						this.dialogFormVisible = false;
						this.getList();
						this.$message({
							showClose: true,
							message: '添加成功！',
							type: 'success'
						});
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			} else if (this.type == 1) {//编辑
				upidentifier(this.form).then((res) => {
					if (res.code == 1) {
						this.form.name = '';
						this.form.identifier = '';
						this.form.id = 0;
						this.dialogFormVisible = false;
						this.getList();
						this.$message({
							showClose: true,
							message: '编辑成功！',
							type: 'success'
						});
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			}
		}
	},
	mounted() {
		//获取列表数据
		this.getList();
	}
}

</script>

<style lang="scss">
@import "../../styles/permission.scss";
</style>